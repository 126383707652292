h1,
h2,
h3,
p,
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

.App {
  padding-top: 20px;
  height: 100vh;
  background: linear-gradient(to top, rgb(8, 8, 8), transparent),
    url(https://grainy-gradients.vercel.app/noise.svg);
}

.App-logo {
  height: 100%;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.calc-form {
  margin: auto;
  height: 95%;
  max-width: 500px;
  border-radius: 15px;
  background: linear-gradient(to bottom, rgb(8, 8, 8), transparent),
    url(https://grainy-gradients.vercel.app/noise.svg);
  box-shadow: 10px 20px 20px 0px rgba(0, 0, 0, 0.5);
}

.calc-name {
  display: flex;
margin: auto;
flex-direction: row;
  height: 5%;
  width: 90%;

}

.calc-screen {
margin: auto;
padding: 2px 10px;
  height: 15%;
  width: 90%;
  border-radius: 10px;
background-color: rgb(56, 56, 56);
box-shadow: inset 0px 0px 20px 0px rgba(255, 255, 255, 0.5);

}

.calc-screen-part {
  display: flex;
  height: calc(100%/2);
  justify-content: end;
  align-items: center;
}

.calc-print {
color: #61DAFB;
text-shadow: 0px 2px 20px #ffffff, 5px 5px 5px #000000;
}
 .top {
  font-size: 24px;
 }

 .bottom {
  font-weight: bolder;
  font-size: 36px;
 }

.calc-buttons {
margin: auto;
  height: 73%;
  width: 90%;
  border-radius: 5%;
  margin-top: 10px;
}

.calc-button {
  height: 14%;
  width: 19%;
  margin: 3%;
  font-size: large;
  font-weight: bolder;
  border-radius: 50%;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
  background-image: -webkit-radial-gradient( 50% 0%, 8% 50%, hsla(0,0%,100%,.5) 0%, rgba(255, 255, 255, 0) 100%),
    -webkit-radial-gradient( 50% 100%, 12% 50%, hsla(0,0%,100%,.6) 0%, hsla(0,0%,100%,0) 100%),
    -webkit-radial-gradient( 0% 50%, 50% 7%, hsla(0,0%,100%,.5) 0%, hsla(0,0%,100%,0) 100%),
    -webkit-radial-gradient( 100% 50%, 50% 5%, hsla(0,0%,100%,.5) 0%, hsla(0,0%,100%,0) 100%),
    -webkit-repeating-radial-gradient( 50% 50%, 100% 100%, hsla(0,0%, 0%,0) 0%, hsla(0,0%, 0%,0) 3%, hsla(0,0%, 0%,.1) 3.5%),
    -webkit-repeating-radial-gradient( 50% 50%, 100% 100%, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0) 6%, hsla(0,0%,100%,.1) 7.5%),
    -webkit-repeating-radial-gradient( 50% 50%, 100% 100%, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0) 1.2%, hsla(0,0%,100%,.2) 2.2%),
    -webkit-radial-gradient( 50% 50%, 200% 50%, hsla(0,0%,90%,1) 5%, hsla(0,0%,85%,1) 30%, hsla(0,0%,60%,1) 100%);
  }

  .calc-button:hover {
    box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.5);
    color: #61DAFB;
    transition-duration: 100ms;
    transform: scale(1.05);
  }
